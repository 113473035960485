import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import * as Auth from './pages/Auth';
import { Dialogs, DialogOpened } from './pages/Dialogs';
import Profile from './pages/Profile';
import Dashboard from './pages/Dashboard';
import Customers from './pages/Customers';
import Error404 from './pages/Errors/Error404';
import Error403 from './pages/Errors/Error403';
import Layout from './components/Layout/Layout';
import { getUserData } from './api/users';
import AlertBar from './components/AlertBar';
import { ConfigProvider } from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US';
import './i18n/i18n';

const RootRouter = () => {
  const userLoggedIn = useSelector((state) => state.auth.user?.email);
  const authCompleted = useSelector((state) =>
    Object.prototype.hasOwnProperty.call(state.auth, 'user'),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData());

    function updateHeight() {
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.innerHeight}px`,
      );
    }
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [dispatch]);

  const ProtectedRoute = ({ element, hasAccess = true }) => {
    if (!authCompleted) return null;
    return userLoggedIn ? (
      hasAccess ? (
        element
      ) : (
        <Error403 />
      )
    ) : (
      <Navigate to="/signin" replace />
    );
  };

  const ProtectedRouteAuth = ({ element }) => {
    if (!authCompleted) return null;
    return userLoggedIn ? <Navigate to="/conversations" replace /> : element;
  };

  return (
    <>
      {userLoggedIn ? <AlertBar onOpen={() => {}} onClose={() => {}} /> : null}
      <Routes>
        <Route
          path="/signin"
          element={<ProtectedRouteAuth element={<Auth.SignIn />} />}
        />
        <Route
          path="/signup"
          element={<ProtectedRouteAuth element={<Auth.SignUp />} />}
        />
        <Route
          path="/forgot"
          element={<ProtectedRouteAuth element={<Auth.Forgot />} />}
        />
        <Route
          path="/forgot/:code"
          element={<ProtectedRouteAuth element={<Auth.ForgotCode />} />}
        />

        {/* Защищенные маршруты */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <Profile />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/customers"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <Customers />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/conversations"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <Dialogs />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/conversations/:dialogId"
          element={<ProtectedRoute element={<DialogOpened />} />}
        />
        <Route
          path="/403"
          element={<ProtectedRoute element={<div>-</div>} hasAccess={false} />}
        />

        {/* Перенаправления */}
        <Route
          path="/"
          element={
            <ProtectedRoute
              element={<Navigate to="/conversations" replace />}
            />
          }
        />
        <Route path="*" element={<ProtectedRoute element={<Error404 />} />} />
      </Routes>
    </>
  );
};

function App() {
  return (
    <ConfigProvider locale={enUS}>
      <RootRouter />
    </ConfigProvider>
  );
}

export default App;
