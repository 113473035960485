import backend from '../../configs/backend';

const signUpUser =
  ({ email, phone, clientId, referrer = window.location.href }) =>
  () =>
    new Promise((resolve) => {
      fetch(backend.applyBaseUrl('/api/users/signup'), {
        method: 'POST',
        body: JSON.stringify({
          ...(email ? { email } : {}),
          ...(phone ? { phone } : {}),
          ...(clientId ? { clientId } : {}),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        referrer,
      }).then((response) => resolve(response));
    });

export default signUpUser;
