import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavBar, Button } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';
import { components as componentsConf } from '../../../../../../configs';
import { dialogsListModeSelector } from '../../../../../../reducers/dialogsListMode';
import useDialogsListFilterStatus from '../../../../../../hooks/dialogs/useDialogsListFilterStatus';
import useDialogsListSort from '../../../../../../hooks/dialogs/useDialogsListSort';
import useDialogsListChannelFilters from '../../../../../../hooks/dialogs/useDialogsListChannelFilters';
import useDialogsListResponsibleUsersFilter from '../../../../../../hooks/dialogs/useDialogsListResponsibleUsersFilter';
import FilterStatusSection from './FilterStatusSection';
import ChannelSection from './ChannelSection';
import ResponsibleUsersFilterSection from './ResponsibleUsersFilterSection';
// import TagSection from './TagSection';

const DialogFilterSections = ({
  contentMode = 'dialogs',
  activeChannels,
  containerVisible,
  setContainerVisible,
}) => {
  const { t } = useTranslation();
  const { filterStatus, updateFilterStatus } = useDialogsListFilterStatus();
  const { sort: currentSortBy, updateSort } = useDialogsListSort();
  const { channelFilters, updateChannelFilters } =
    useDialogsListChannelFilters();
  const { responsibleUsersFilter, updateResponsibleUsersFilter } =
    useDialogsListResponsibleUsersFilter();

  const {
    pages: {
      dialogs: {
        filter: {
          sections: { [contentMode]: filterSections },
          statusFiltersRelation: {
            [contentMode]: { mainFilter: mainStatusFilters },
          },
        },
      },
    },
  } = componentsConf;

  const [activeFilterStatus, setActiveFilterStatus] = useState(
    mainStatusFilters.includes(filterStatus) ? filterStatus : '',
  );
  const [activeSortType, setActiveSortType] = useState(currentSortBy);
  const [activeChannelFilters, setActiveChannelFilter] =
    useState(channelFilters);
  const [activeResponsibleUsersFilter, setResponsibleUsersFilter] = useState(
    responsibleUsersFilter,
  );

  const renderSectionComponent = (section) => {
    if (section === 'filters') {
      return (
        <FilterStatusSection
          key={section}
          activeFilterStatus={activeFilterStatus}
          setActiveFilterStatus={setActiveFilterStatus}
          activeSortType={activeSortType}
          setActiveSortType={setActiveSortType}
          dialogsListMode={contentMode}
        />
      );
    }
    if (section === 'extendedFilters') {
      return (
        <div key={section}>
          <ChannelSection
            channelFilters={activeChannelFilters}
            updateChannelFilters={setActiveChannelFilter}
          />
          <ResponsibleUsersFilterSection
            responsibleUsersFilter={activeResponsibleUsersFilter}
            updateResponsibleUsersFilter={setResponsibleUsersFilter}
          />
          {/* <TagSection containerVisible={containerVisible} /> */}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <NavBar
        backIcon={<CloseOutline />}
        onBack={() => setContainerVisible(false)}
        right={
          <Button
            color="primary"
            fill="none"
            style={{ padding: 3 }}
            onClick={() => {
              updateFilterStatus('SHOW_ALL');
              updateSort('SHOW_RECENT_UPDATED');
              updateChannelFilters(activeChannels.map((item) => item._id));
              updateResponsibleUsersFilter([]);
              setContainerVisible(false);
            }}
          >
            {t('DialogFilterSections.clear')}
          </Button>
        }
      >
        {t('DialogFilterSections.filter')}
      </NavBar>
      <div style={{ overflowY: 'auto', height: 'calc(100% - 45px)' }}>
        {filterSections.map(renderSectionComponent)}
        <div style={{ padding: '16px' }}>
          <Button
            block
            color="primary"
            size="large"
            onClick={() => {
              updateFilterStatus(
                activeFilterStatus ? activeFilterStatus : 'SHOW_ALL',
              );
              updateSort(activeSortType);
              updateChannelFilters(activeChannelFilters);
              updateResponsibleUsersFilter(activeResponsibleUsersFilter);
              setContainerVisible(false);
            }}
          >
            {t('DialogFilterSections.apply')}
          </Button>
        </div>
      </div>
    </>
  );
};

DialogFilterSections.displayName = 'DialogFilterSections';

DialogFilterSections.propTypes = {
  contentMode: PropTypes.string.isRequired,
  activeChannels: PropTypes.array,
  containerVisible: PropTypes.bool.isRequired,
  setContainerVisible: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  contentMode: dialogsListModeSelector(state),
});

export default connect(mapStateToProps)(DialogFilterSections);
