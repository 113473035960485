import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { getTemplates } from '../../../../api/templates';
import {
  getChannelIdFromDialogSelector,
  getMetadataFromDialogSelector,
  getResponsibleIdFromDialogSelector,
} from '../../../../reducers/dialogs';
// import { clientsArraySelector } from '../../../../reducers/clients';
import dialogFormChannelSelector from '../../../../selectors/dialogFormChannelSelector';
// import getActiveChannelsFromState from '../../../../selectors/getActiveChannelsFromState';
// import getDeletedChannelsFromState from '../../../../selectors/getDeletedChannelsFromState';
// import {
//   pbxAccountsArraySelector,
//   deletedPbxAccountsArraySelector,
// } from '../../../../reducers/pbx-accounts';
// import { getContacts } from '../../../../api/contacts';

import DialogForm from './DialogForm';

const DialogFormContainer = ({ dialog, dialogId }) => {
  const getUserIdSelector = (state) =>
    R.pathOr(null, ['auth', 'user', '_id'], state);

  const authorId = useSelector((state) => getUserIdSelector(state));
  const channelId = useSelector((state) =>
    getChannelIdFromDialogSelector(state, { dialogId }),
  );
  const metadata = useSelector((state) =>
    getMetadataFromDialogSelector(state, { dialogId }),
  );
  const responsibleId = useSelector((state) =>
    getResponsibleIdFromDialogSelector(state, { dialogId }),
  );
  const channel = useSelector((state) =>
    dialogFormChannelSelector(state, { dialogId }),
  );

  // } = useSelector((state) => ({
  // activeChannels: [
  //   ...(getActiveChannelsFromState(state, { dialogId }) || []),
  //   ...(pbxAccountsArraySelector(state, { dialogId }) || []),
  // ],
  // deletedChannels: [
  //   ...(getDeletedChannelsFromState(state, { dialogId }) || []),
  //   ...(deletedPbxAccountsArraySelector(state, { dialogId }) || []),
  // ],
  // clients: clientsArraySelector(state, ownProps, { dialogId }).filter((c) => {
  //   if (!c.contacts) {
  //     return false;
  //   }
  //   let returnValue = false;
  //   c.contacts.forEach((cc) => {
  //     if (cc.service === 'mail') {
  //       returnValue = true;
  //     }
  //   });
  //   return returnValue;
  // }),
  // draft: draftSelector(state, {
  //   dialogId: getDialogIdSelector(state, ownProps),
  // }),
  // }));

  return (
    <>
      {channel || responsibleId === authorId ? (
        <DialogForm
          authorId={authorId}
          dialogId={dialogId}
          dialog={dialog}
          channelId={channelId}
          metadata={metadata}
          responsibleId={responsibleId}
          channel={channel}
        />
      ) : null}
    </>
  );
};

DialogFormContainer.propTypes = {
  dialog: PropTypes.object,
  dialogId: PropTypes.string,
};

export default DialogFormContainer;
