import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Form, Input, Button, AutoCenter } from 'antd-mobile';
import { Link } from 'react-router-dom';
import { handleForgot } from '../../api/auth';

const Forgot = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [emailSent, setEmailSend] = useState(false);
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSubmit = () => {
    setIsButtonDisabled(true);
    dispatch(handleForgot({ email }))
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (!data.err) {
          setEmailSend(true);
          setError(null);
        } else {
          setError(data.err);
        }
        setIsButtonDisabled(false);
      })
      .catch((err) => {
        // этот catch для ошибок типа no route to host
        setError(err.toString());
        setIsButtonDisabled(false);
      });
  };

  return (
    <section className="SignApp">
      <div className="SignContent">
        <div className="SignForm">
          <AutoCenter className="SignTitle">Yeahdesk</AutoCenter>
          <AutoCenter className="SignDescription">
            {t('Forgot.description')}
          </AutoCenter>
          <Form
            validateMessages={{
              required: t('Forgot.validateMessages.required'),
            }}
            onFinish={handleSubmit}
            layout="vertical"
            requiredMarkStyle="none"
            footer={
              <Button
                block
                type="submit"
                color="primary"
                disabled={isButtonDisabled}
              >
                {t('Forgot.sendANewPassword')}
              </Button>
            }
          >
            <Form.Item name="email" rules={[{ required: true }]}>
              <Input
                autoComplete="email"
                type="email"
                placeholder={t('Forgot.yourEmail')}
                value={email}
                onChange={setEmail}
                clearable
                onlyShowClearWhenFocus={false}
              />
            </Form.Item>
          </Form>
        </div>
        <div>
          {error ? (
            <div className="SignErr">
              <Trans i18nKey={error} />
            </div>
          ) : null}
          {emailSent ? (
            <div className="SignSuccess">
              {t('Forgot.success')} {email}
            </div>
          ) : null}
        </div>
      </div>
      <div className="SignBottom">
        <AutoCenter>
          <Trans
            i18nKey="Forgot.linkSignInAndSignUn"
            components={{
              a1: <Link to="/signip" aria-label="Sign Ip" />,
              a2: <Link to="/signup" aria-label="Sign Up" />,
            }}
          />
        </AutoCenter>
      </div>
    </section>
  );
};

export default Forgot;
