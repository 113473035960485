import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CheckList, Ellipsis } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { components as componentsConf } from '../../../../../../configs';
import './FilterStatusSection.css';

const FilterStatusSection = ({
  activeFilterStatus,
  setActiveFilterStatus,
  activeSortType,
  setActiveSortType,
  dialogsListMode = 'dialogs',
}) => {
  const { t } = useTranslation();
  const {
    pages: {
      dialogs: {
        filter: {
          availableSortTypes: { [dialogsListMode]: dialogsSortTypes },
          statusFiltersRelation: {
            [dialogsListMode]: { mainFilter: mainStatusFilters },
          },
          statusLabels: {
            [dialogsListMode]: { mainFilter: dialogsStatusLabels },
          },
          // sortLabels: { [dialogsListMode]: dialogsSortLabels },
          sortTooltipText: { [dialogsListMode]: dialogsSortTooltipText },
        },
      },
    },
  } = componentsConf;
  const sortTypesKeys = Object.keys(dialogsSortTypes);

  const handleStatusFilterChange = (status) => {
    setActiveFilterStatus(status[0] ? status[0] : '');
  };

  const handleSortChange = (type) => {
    if (activeSortType === type) {
      return;
    }
    setActiveSortType(type);
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <div className="FilterStatusSection__title">
        {t('FilterStatusSection.title.status')}
      </div>
      <CheckList
        defaultValue={[activeFilterStatus]}
        onChange={(status) => handleStatusFilterChange(status)}
      >
        {mainStatusFilters.map((status) => (
          <CheckList.Item key={status} value={status}>
            <Ellipsis
              direction="end"
              content={t(dialogsStatusLabels[status])}
            />
          </CheckList.Item>
        ))}
      </CheckList>
      <div className="FilterStatusSection__title">
        {t('FilterStatusSection.title.sort')}
      </div>
      <CheckList value={[activeSortType]}>
        {sortTypesKeys.map((sortType) => (
          <Fragment key={sortType}>
            {dialogsSortTypes[sortType].map((type) => (
              <CheckList.Item
                key={type}
                value={type}
                onClick={() => handleSortChange(type)}
              >
                <Ellipsis
                  direction="end"
                  content={t(dialogsSortTooltipText[type])}
                />
              </CheckList.Item>
            ))}
          </Fragment>
        ))}
      </CheckList>
    </div>
  );
};

FilterStatusSection.displayName = 'FilterStatusSection';

FilterStatusSection.propTypes = {
  activeFilterStatus: PropTypes.string.isRequired,
  setActiveFilterStatus: PropTypes.func.isRequired,
  activeSortType: PropTypes.string.isRequired,
  setActiveSortType: PropTypes.func.isRequired,
  dialogsListMode: PropTypes.string.isRequired,
};

export default FilterStatusSection;
