import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, Input, Button, AutoCenter, Checkbox } from 'antd-mobile';
import * as ac from '../../actions'; // ac - stands for 'Action Creator'
import { loginUser } from '../../api/auth';
import getNewJwtToken from '../../utils/getNewJwtToken';
import * as websocketsActions from '../../utils/websocketsActions';
import './Auth.css';

const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const sessionSaved = useSelector(
  //   (state) => state.cacheStatus.currentSessionSaved,
  // );

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [needForgetPwd, setNeedForgetPwd] = useState(false);

  const forgetmeHandler = () => {
    setNeedForgetPwd(!needForgetPwd);
    dispatch(ac.dontRemember(!needForgetPwd));
  };

  const handleSubmit = () => {
    setIsButtonDisabled(true);
    dispatch(loginUser({ email, password }))
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(async (response) => {
        setError(response.err ? response.err.toString() : null);
        setIsButtonDisabled(false);

        if (response.refresh_token) {
          const jwtToken = await getNewJwtToken(response.refresh_token);
          await websocketsActions.subscribeToEvents(jwtToken);
          dispatch(ac.loggedIn(response.user, response.refresh_token));
        }
      })
      .catch((err) => {
        setError(err.toString());
        setIsButtonDisabled(false);
      });
  };

  return (
    <section className="SignApp">
      <div className="SignContent">
        <div className="SignForm">
          <AutoCenter className="SignTitle">Yeahdesk</AutoCenter>
          <AutoCenter className="SignDescription">
            {t('SignIn.description')}
          </AutoCenter>
          <Form
            validateMessages={{
              required: t('SignIn.validateMessages.required'),
            }}
            onFinish={handleSubmit}
            layout="vertical"
            requiredMarkStyle="none"
            footer={
              <>
                <Button
                  block
                  type="submit"
                  color="primary"
                  disabled={isButtonDisabled}
                >
                  {t('SignIn.login')}
                </Button>
                <AutoCenter className="SignForgot">
                  <Link to="/forgot">{t('SignIn.linkForgot')}</Link>
                </AutoCenter>
              </>
            }
          >
            <Form.Item name="email" rules={[{ required: true }]}>
              <Input
                autoComplete="email"
                type="email"
                placeholder={t('SignIn.yourEmail')}
                value={email}
                onChange={setEmail}
                clearable
                onlyShowClearWhenFocus={false}
              />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true }]}>
              <Input
                autoComplete="current-password"
                type="password"
                placeholder={t('SignIn.password')}
                value={password}
                onChange={setPassword}
                clearable
                onlyShowClearWhenFocus={false}
              />
            </Form.Item>
            <Form.Item>
              <Checkbox checked={needForgetPwd} onChange={forgetmeHandler}>
                {t('SignIn.needForgetPwd')}
              </Checkbox>
            </Form.Item>
          </Form>
        </div>
        <div>
          {error ? (
            <div className="SignErr">
              <Trans i18nKey="SignIn.error" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="SignBottom">
        <AutoCenter>
          <Trans
            i18nKey="SignIn.linkSignUp"
            components={{ a: <Link to="/signup" aria-label="Sign Up" /> }}
          />
        </AutoCenter>
      </div>
    </section>
  );
};

export default SignIn;
