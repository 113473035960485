import React, { useEffect, useState } from 'react';
import { NavBar, Button } from 'antd-mobile';
import { SearchOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';
import DialogsList from './DialogsList';
import DialogSearch from './DialogsList/DialogsListHeader/DialogSearch';
import useDialogsListSearch from '../../hooks/dialogs/useDialogsListSearch';
import './Dialogs.css';

const Dialogs = () => {
  const { t } = useTranslation();

  const [isSearch, setIsSearch] = useState(false);
  const { search: globalSearch, updateSearch: updateGlobalSearch } =
    useDialogsListSearch();

  useEffect(() => {
    // переделать на глобальное состояние
    if (globalSearch) {
      setIsSearch(true);
    }
  }, []);

  const right = (
    <Button fill="none" onClick={() => setIsSearch(true)}>
      <SearchOutline fontSize={24} />
    </Button>
  );

  return (
    <div className="dialogs">
      {isSearch ? (
        <DialogSearch
          toggle={setIsSearch}
          globalSearch={globalSearch}
          updateGlobalSearch={updateGlobalSearch}
        />
      ) : (
        <NavBar right={right} back={null}>
          <span className="dialogs-title">{t('Layout.conversations')}</span>
        </NavBar>
      )}
      <DialogsList />
    </div>
  );
};

export default Dialogs;
