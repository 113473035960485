import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Form, Input, Button, AutoCenter } from 'antd-mobile';
import { Link } from 'react-router-dom';
import { signUpUser } from '../../api/auth';
import { constructReferrerWithUtmParameters } from '../../utils/registrationUtmParameters';
import './Auth.css';

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [phone, setPhone] = useState('+7');
  const [error, setError] = useState(false);
  const [isSubmitDone, setSubmitDone] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');

    const cvLength = currentValue.length;

    //  https://stackoverflow.com/questions/55988065/react-how-to-format-phone-number-as-user-types

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 2 || currentValue[0] !== '7') return `+${currentValue}`;
      if (cvLength < 5)
        return `+${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)})`;
      if (cvLength < 7)
        return `+${currentValue.slice(0, 1)} (${currentValue.slice(
          1,
          4,
        )}) ${currentValue.slice(4, 7)}`;
      if (cvLength < 9)
        return `+${currentValue.slice(0, 1)} (${currentValue.slice(
          1,
          4,
        )}) ${currentValue.slice(4, 7)} ${currentValue.slice(7, 10)}`;
      return `+${currentValue.slice(0, 1)} (${currentValue.slice(
        1,
        4,
      )}) ${currentValue.slice(4, 7)} ${currentValue.slice(
        7,
        9,
      )} ${currentValue.slice(9, 11)}`;
    }
    return value;
  };

  const handleChangePhone = (value) => {
    setPhone(normalizeInput(value, phone));
    form.setFieldsValue({ phone: normalizeInput(value, phone) });
  };

  const handleSubmit = () => {
    setIsButtonDisabled(true);
    const referrer = constructReferrerWithUtmParameters();
    dispatch(
      signUpUser({
        email: form.getFieldValue('email'),
        phone,
        referrer,
      }),
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (!data.err) {
          setError(null);
          setSubmitDone(true);
        } else {
          setError(data.err);
        }
        setIsButtonDisabled(false);
      })
      .catch((err) => {
        // этот catch для ошибок типа no route to host
        setError(err.toString());
        setIsButtonDisabled(false);
      });
  };

  return (
    <section className="SignApp">
      <div className="SignContent">
        <div className="SignForm">
          <AutoCenter className="SignTitle">Yeahdesk</AutoCenter>
          <Form
            form={form}
            initialValues={{ email: '', phone }}
            validateMessages={{
              required: t('SignUn.validateMessages.required'),
            }}
            onFinish={handleSubmit}
            layout="vertical"
            requiredMarkStyle="none"
            footer={
              <Button
                block
                type="submit"
                color="primary"
                disabled={isButtonDisabled}
              >
                {t('SignUn.createAnAccount')}
              </Button>
            }
          >
            <Form.Item
              label={t('SignUn.emaillabel')}
              name="email"
              rules={[{ required: true }]}
            >
              <Input
                type="email"
                placeholder={t('SignUn.yourEmail')}
                clearable
                onlyShowClearWhenFocus={false}
              />
            </Form.Item>
            <Form.Item
              label={t('SignUn.phoneLabel')}
              name="phone"
              rules={[{ required: true }]}
            >
              <Input
                type="tel"
                placeholder={t('SignUn.yourPhone')}
                onChange={handleChangePhone}
                clearable
                onlyShowClearWhenFocus={false}
              />
            </Form.Item>
          </Form>
        </div>
        <div>
          {error ? <div className="SignErr">{t('SignUn.error')}</div> : null}
          {isSubmitDone ? (
            <div className="SignSuccess">
              <Trans i18nKey="SignUn.success" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="SignBottom">
        <AutoCenter>
          <Trans
            i18nKey="SignUn.linkSignIp"
            components={{ a: <Link to="/signip" aria-label="Sign Ip" /> }}
          />
        </AutoCenter>
      </div>
    </section>
  );
};

export default SignUp;
