import analyticsConf from '../configs/analytics';

const {
  utm: {
    queryParameterRegexp,
    storage: { key: defaultKey },
  },
} = analyticsConf;

// TODO -> define singleton to work with utm parameters
function saveRegistrationUtmParameters(
  utmParameters = [],
  key = defaultKey,
  storage = localStorage,
) {
  storage.setItem(key, JSON.stringify(utmParameters));
}

/**
 * @returns {string[]}
 */
export function getRegistrationUtmParameters(
  key = defaultKey,
  storage = localStorage,
) {
  const savedUtmParametersString = storage.getItem(key) || '[]';
  try {
    const utmParameters = JSON.parse(savedUtmParametersString);
    return utmParameters;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return [];
  }
}

function deleteRegistrationUtmParameters(
  key = defaultKey,
  storage = localStorage,
) {
  storage.removeItem(key);
}

function extractUtmParametersFromQuery(location = window.location) {
  const utmParameters = [];

  if (location.search) {
    const utmParametersFromQuery = location.search
      .slice(1)
      .split('&')
      .filter((v) => v.length !== 0 && v.match(queryParameterRegexp));
    if (utmParametersFromQuery.length !== 0) {
      utmParameters.push(...utmParametersFromQuery);
    }
  }

  return utmParameters;
}

export function extractAndSaveRegistrationUtmParametersFromQuery() {
  const utmParameters = extractUtmParametersFromQuery(window.location);
  if (utmParameters.length) {
    saveRegistrationUtmParameters(utmParameters);
  }
}

export function constructReferrerWithUtmParameters() {
  let referrer = window.location.href;

  const utmParameters = getRegistrationUtmParameters();
  if (utmParameters.length !== 0) {
    const referrerLocation = { ...window.location };
    referrerLocation.search = `?${utmParameters.join('&')}`;
    referrer = `${referrerLocation.origin}${referrerLocation.pathname}${referrerLocation.search}`;

    deleteRegistrationUtmParameters();
  }

  return referrer;
}
export default {
  extractAndSaveRegistrationUtmParametersFromQuery,
  constructReferrerWithUtmParameters,
};
