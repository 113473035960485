import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { SearchBar } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';
import { components as componentsConf } from '../../../../configs';

import usePrevious from '../../../../hooks/usePrevious';
import debounce from '../../../../utils/debounce';
import useDialogsListContentSearchMode from '../../../../hooks/dialogs/useDialogsListContentSearchMode';

const DialogSearch = ({ toggle, globalSearch, updateGlobalSearch }) => {
  const { t } = useTranslation();

  const {
    pages: {
      dialogs: {
        search: {
          debounceTimeout: searchDebounceTimeout,
          // contentSearchMode: contentSearchModeConf,
        },
      },
    },
  } = componentsConf;

  const [localSearch, setLocalSearch] = useState(globalSearch);

  /**
   * React to global search state change:
   * update local search
   */
  const prevGlobalSearch = usePrevious(globalSearch);

  const updateLocalSearchToMatchGlobal = useCallback(
    (globalSearchValue) => {
      if (globalSearchValue !== localSearch) {
        setLocalSearch(globalSearchValue);
      }
    },
    [localSearch],
  );

  useEffect(() => {
    if (prevGlobalSearch !== globalSearch) {
      updateLocalSearchToMatchGlobal(globalSearch);
    }
  }, [prevGlobalSearch, globalSearch, updateLocalSearchToMatchGlobal]);

  const handleLocalSearchChange = useCallback(
    (value) => setLocalSearch(value),
    [],
  );

  const debouncedGlobalSearchUpdate = useRef(
    debounce(updateGlobalSearch, searchDebounceTimeout),
  );

  const resetSearch = useCallback(() => {
    if (localSearch) {
      setLocalSearch('');
    }
    if (globalSearch) {
      debouncedGlobalSearchUpdate.current('');
    }
  }, [globalSearch, localSearch, debouncedGlobalSearchUpdate]);

  const handleOnSearch = useCallback(
    (searchValue) => {
      if (!searchValue) {
        resetSearch();
        return;
      }
      if (searchValue !== globalSearch) {
        debouncedGlobalSearchUpdate.current(searchValue);
      }
    },
    [globalSearch, resetSearch, debouncedGlobalSearchUpdate],
  );

  const handleOnSearchByEnter = useCallback(
    () => handleOnSearch(localSearch),
    [handleOnSearch, localSearch],
  );

  const { contentSearchMode, updateContentSearchMode } =
    useDialogsListContentSearchMode();

  // const handleContentSearchModeSelect = useCallback(
  //   (mode) => {
  //     if (mode !== contentSearchMode) {
  //       updateContentSearchMode(mode);
  //     }
  //   },
  //   [contentSearchMode, updateContentSearchMode],
  // );

  return (
    <div style={{ padding: '6.5px 0 6.5px 12px' }}>
      <SearchBar
        value={localSearch}
        placeholder={t(`DialogSearch.${contentSearchMode}`)}
        onChange={handleLocalSearchChange}
        onSearch={handleOnSearch}
        onClear={handleOnSearch}
        onPressEnter={handleOnSearchByEnter}
        cancelText={<CloseOutline fontSize={24} />}
        showCancelButton={() => true}
        onCancel={() => {
          handleOnSearch();
          toggle(false);
        }}
      />
    </div>
  );
};

export default memo(DialogSearch);
