const dialogsConfig = {
  channelsView: {
    channelNeedEt: ['telegram', 'whatsapp', 'demo'],
    channelNeedId: ['vk', 'custom', 'chatra'],
  },
  search: {
    contentSearchMode: {
      availableModes: [
        'dialogClient',
        'dialogTitle',
        // 'dialogMessagesText'
      ],
      defaultMode: 'dialogTitle',
    },
  },
  selectedClientData: {
    additionalData: {
      sections: {
        dialogs: {
          initialLimit: 3,
          limit: 5,
        },
        tasks: {
          initialLimit: 3,
          limit: 5,
        },
      },
      sectionTypeLabels: {
        contacts: 'Контакты',
        dialogs: 'Другие диалоги',
        tasks: 'Задачи',
        amocrm: 'amoCRM',
      },
      communicationHistory: {
        limit: 20,
      },
    },
  },
  variables: {
    responsible: {
      placeholder: {
        ru: 'Ответственный за диалог',
      },
      notFound: {
        ru: 'Не найдено',
      },
    },
    buttonStatus: {
      closed: {
        ru: 'Открыть',
      },
      opened: {
        ru: 'Закрыть',
      },
      waiting: {
        ru: 'Открыть',
      },
    },
    dialogHeader: {
      buttonWaiting: {
        postponeDialog: {
          ru: 'Отложить диалог',
        },
        forOneHour: {
          ru: 'На 1 час',
        },
        forTwoHours: {
          ru: 'На 2 часа',
        },
        forFourHours: {
          ru: 'На 4 часа',
        },
        forTomorrow: {
          ru: 'До завтра:',
        },
        forMorning: {
          ru: 'утра',
        },
        forMonday: {
          ru: 'До понедельника:',
        },
        longPeriod: {
          ru: 'Бессрочно',
        },
        customPeriod: {
          ru: 'Выбрать дату и время',
        },
        postpone: {
          ru: 'Отложить',
        },
        cancel: {
          ru: 'Отмена',
        },
        setTimeToButtonWaiting: {
          ru: 'Укажите дату и время, до которых нужно отложить текущий диалог.',
        },
        choosePeriodInFeature: {
          ru: 'Выберите дату и время в будущем',
        },
        dialogOpenIn: {
          ru: 'Диалог откроется:',
        },
        chooseDate: {
          ru: 'Выберите дату',
        },
        chooseTime: {
          ru: 'Выберите время',
        },
        timePeriodButtonsTitle: {
          ru: 'Отложить в один клик:',
        },
        timePeriodButtonsAWeek: {
          ru: 'На неделю',
        },
        timePeriodButtonsMonth: {
          ru: 'На месяц',
        },
        timePeriodButtonsQuarter: {
          ru: 'На квартал',
        },
        timePeriodButtonsSixMonths: {
          ru: 'На полгода',
        },
        timePeriodButtonsYear: {
          ru: 'На год',
        },
      },
    },
    dialogRightPanel: {
      selectedClientCard: {
        channel: {
          ru: 'Канал',
        },
        client: {
          ru: 'Клиент',
        },
        clientDescription: {
          ru: 'Описание клиента 128 символов',
        },
        showMore: {
          ru: 'Показать ещё',
        },
        contacts: {
          ru: 'контакта',
        },
        lastDialogs: {
          ru: 'Последние диалоги',
        },
        history: {
          ru: 'История',
        },
        card: {
          ru: 'Карточка',
        },
        actualTasks: {
          ru: 'Актуальные задачи',
        },
        createNewTask: {
          ru: 'Создать',
        },
        toParticipantsList: {
          ru: 'К списку участников',
        },
        participants: {
          ru: 'Участники диалога',
        },
        participantsTooltip: {
          ru: 'Все клиенты, которые получают от вас ответы в этом диалоге.',
        },
      },
    },
  },
};

export default dialogsConfig;
