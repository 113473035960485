import backend from '../../configs/backend';

const handleForgot = (user) => () =>
  new Promise((resolve) => {
    fetch(backend.applyBaseUrl('/api/users/forgot'), {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => resolve(response));
  });

export default handleForgot;
