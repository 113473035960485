import { useSelector } from 'react-redux';

const useDialogResponsibleFilter = () => {
  const allUsers = useSelector((state) => Object.values(state.users));
  const activeUsers = allUsers.filter((user) => user.status !== 'deleted');
  const deletedUsers = allUsers.filter((user) => user.status === 'deleted');

  return {
    activeUsers,
    deletedUsers,
    allUsers,
  };
};

export default useDialogResponsibleFilter;
