import backend from '../../configs/backend';

const forgotCode = (code) => () =>
  new Promise((resolve) => {
    fetch(backend.applyBaseUrl(`/api/users/forgot/${code}`), {
      method: 'DELETE',
    }).then((response) => resolve(response));
  });

export default forgotCode;
