import React, { useState } from 'react';
import {
  Collapse,
  Checkbox,
  List,
  Tag,
  Popup,
  NavBar,
  Ellipsis,
} from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDialogResponsibleFilter from '../../../../../../hooks/dialogs/dialogFilters/useDialogResponsibleFilter';
import './ResponsibleUsersFilterSection.css';

const ResponsibleUsersFilterSection = ({
  responsibleUsersFilter,
  updateResponsibleUsersFilter,
}) => {
  const { t } = useTranslation();
  const { activeUsers, deletedUsers } = useDialogResponsibleFilter();

  const [popupVisible, setPopupVisible] = useState(false);

  const onChangeResponsibleUsersFilter = (value) => {
    updateResponsibleUsersFilter(value);
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation(); // предотвращаем закрытие Collapse
  };

  const getActiveTags = (activeIds, allUsers, allDeletedUsers) => {
    const allUsersIds = allUsers.map((i) => i._id);
    const deletedUsersIds = allDeletedUsers.map((i) => i._id);

    const hasAllFirstTags =
      allUsersIds.length > 1 &&
      allUsersIds.every((id) => activeIds.includes(id));
    const hasAllSecondTags =
      deletedUsersIds.length > 1 &&
      deletedUsersIds.every((id) => activeIds.includes(id));

    const result = [];

    if (hasAllFirstTags) {
      result.push({
        _id: 'activeUsers',
        name: 'ResponsibleUsersFilterSection.allUsers',
      });
    } else {
      result.push(
        ...allUsers.filter((i) => activeIds.includes(i._id)).map((i) => i),
      );
    }

    if (hasAllSecondTags) {
      result.push({
        _id: 'deletedUsers',
        name: 'ResponsibleUsersFilterSection.deletedUsers',
      });
    } else {
      result.push(
        ...allDeletedUsers
          .filter((i) => activeIds.includes(i._id))
          .map((i) => i),
      );
    }

    return result;
  };

  const indeterminateCheckbox = (active, users) =>
    active.some((id) => users.some((i) => i._id === id));

  const checkedCheckbox = (users, active) =>
    users.every((i) => active.includes(i._id));

  return (
    <div>
      <div className="ResponsibleUsersFilterSection__title">
        {t('ResponsibleUsersFilterSection.title')}
      </div>
      <List>
        <List.Item onClick={() => setPopupVisible(true)}>
          {responsibleUsersFilter.length === 0 ? (
            <Ellipsis
              direction="end"
              content={t('ResponsibleUsersFilterSection.selectUsers')}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '5px',
                padding: '2.75px 0',
              }}
            >
              {getActiveTags(
                responsibleUsersFilter,
                activeUsers,
                deletedUsers,
              ).map((item) => (
                <Tag
                  color="default"
                  key={item._id}
                  style={{ fontSize: '14px' }}
                >
                  {t(item.name)}
                </Tag>
              ))}
            </div>
          )}
        </List.Item>
      </List>
      <Popup
        destroyOnClose={true}
        visible={popupVisible}
        bodyStyle={{ height: 'var(--app-height)' }}
      >
        <NavBar
          backIcon={<CloseOutline />}
          onBack={() => setPopupVisible(false)}
        >
          {t('ResponsibleUsersFilterSection.title')}
        </NavBar>
        <div style={{ overflowY: 'auto', height: 'calc(100% - 45px)' }}>
          <Collapse defaultActiveKey={['activeUsers', 'deletedUsers']}>
            <Collapse.Panel
              key="activeUsers"
              title={
                <div
                  onClick={handleCheckboxClick}
                  style={{ display: 'inline-flex' }}
                >
                  <Checkbox
                    indeterminate={
                      indeterminateCheckbox(
                        responsibleUsersFilter,
                        activeUsers,
                      ) && !checkedCheckbox(activeUsers, responsibleUsersFilter)
                    }
                    checked={checkedCheckbox(
                      activeUsers,
                      responsibleUsersFilter,
                    )}
                    onChange={(checked) => {
                      if (checked) {
                        onChangeResponsibleUsersFilter([
                          ...responsibleUsersFilter,
                          ...activeUsers.map((i) => i._id),
                        ]);
                      } else {
                        const activeUsersIds = activeUsers.map(
                          (tag) => tag._id,
                        );
                        onChangeResponsibleUsersFilter(
                          responsibleUsersFilter.filter(
                            (id) => !activeUsersIds.includes(id),
                          ),
                        );
                      }
                    }}
                  >
                    <Ellipsis
                      direction="end"
                      content={t('ResponsibleUsersFilterSection.allUsers')}
                    />
                  </Checkbox>
                </div>
              }
            >
              <Checkbox.Group
                value={responsibleUsersFilter}
                onChange={onChangeResponsibleUsersFilter}
              >
                <List className="ResponsibleUsersFilterSection__list">
                  {activeUsers.map((user) => (
                    <List.Item key={user._id}>
                      <Checkbox value={user._id}>
                        <Ellipsis direction="end" content={user.name} />
                      </Checkbox>
                    </List.Item>
                  ))}
                </List>
              </Checkbox.Group>
            </Collapse.Panel>
            <Collapse.Panel
              key="deletedUsers"
              title={
                <div
                  onClick={handleCheckboxClick}
                  style={{ display: 'inline-flex' }}
                >
                  <Checkbox
                    indeterminate={
                      indeterminateCheckbox(
                        responsibleUsersFilter,
                        deletedUsers,
                      ) &&
                      !checkedCheckbox(deletedUsers, responsibleUsersFilter)
                    }
                    checked={checkedCheckbox(
                      deletedUsers,
                      responsibleUsersFilter,
                    )}
                    onChange={(checked) => {
                      if (checked) {
                        onChangeResponsibleUsersFilter([
                          ...responsibleUsersFilter,
                          ...deletedUsers.map((i) => i._id),
                        ]);
                      } else {
                        const deletedUsersIds = deletedUsers.map((i) => i._id);
                        onChangeResponsibleUsersFilter(
                          responsibleUsersFilter.filter(
                            (id) => !deletedUsersIds.includes(id),
                          ),
                        );
                      }
                    }}
                  >
                    <Ellipsis
                      direction="end"
                      content={t('ResponsibleUsersFilterSection.deletedUsers')}
                    />
                  </Checkbox>
                </div>
              }
            >
              <Checkbox.Group
                value={responsibleUsersFilter}
                onChange={onChangeResponsibleUsersFilter}
              >
                <List className="ResponsibleUsersFilterSection__list">
                  {deletedUsers.map((user) => (
                    <List.Item key={user._id}>
                      <Checkbox value={user._id}>
                        <Ellipsis direction="end" content={user.name} />
                      </Checkbox>
                    </List.Item>
                  ))}
                </List>
              </Checkbox.Group>
            </Collapse.Panel>
          </Collapse>
        </div>
      </Popup>
    </div>
  );
};

ResponsibleUsersFilterSection.displayName = 'ResponsibleUsersFilterSection';

ResponsibleUsersFilterSection.propTypes = {
  responsibleUsersFilter: PropTypes.array.isRequired,
  updateResponsibleUsersFilter: PropTypes.func.isRequired,
};

export default React.memo(ResponsibleUsersFilterSection);
