import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AutoCenter, SpinLoading } from 'antd-mobile';
import * as ac from '../../actions'; // ac - stands for 'Action Creator'
import { forgotCode } from '../../api/auth';

const ForgotCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { code } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(forgotCode(code))
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        if (response.err) {
          throw new Error(response.err);
        }
        dispatch(ac.loggedIn(response.user, response.refresh_token));
      })
      .catch((err) => {
        setError(err.toString());
      });
  }, []);

  return (
    <section className="SignApp">
      <div className="SignContent">
        {error ? (
          <>
            <AutoCenter className="SignTitle">Yeahdesk</AutoCenter>
            <div className="SignErr">{t('ForgotCode.error')}</div>
          </>
        ) : (
          <AutoCenter>
            <SpinLoading color="default" />
          </AutoCenter>
        )}
      </div>
    </section>
  );
};

export default ForgotCode;
